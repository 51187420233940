import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, forkJoin, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment, BASE_URL } from '../../../../environments/environment';
import { TokenModel } from '../models/token.model';
import { CurrentUserModel } from '../models/current-user.model';
import { OrganisationInfoModel } from '../models/organisation-info.model';
import { CurrentModel } from '../models/current.model';
import { ChannelModel } from '../models/channel.model';
import { OrganisationModel } from '../models/organisationModel';
import { RolesModel } from '../models/roles.model';
import { RegisterPostModel } from '../models/resister-post.model';
import { RegisterGetModel } from '../models/register-get.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  static readonly TOKEN = 'access_token';
  static readonly CHANNEL_ID = 'channel_id';
  static readonly CHANNEL_UUID = 'channel_uuid';
  static readonly MAIN_CHANNEL = 'mainChannel';

  private currentUserSubject: BehaviorSubject<CurrentUserModel> = new BehaviorSubject<any>(null);
  private currentChannel: BehaviorSubject<ChannelModel> = new BehaviorSubject<any>(null);

  constructor(private httpClient: HttpClient, private router: Router) {}

  toDashboardIfAuthenticated() {
    if (!this.isTokenExpired()) this.router.navigate(['/dashboard']).then(value => console.log('Routing to dashboard page', value));
  }

  authenticate(username: string, password: string) {
    return this.httpClient.post<TokenModel>(BASE_URL.concat(environment.login), { login: username, password: password });
  }

  register(requestBody: RegisterPostModel): Observable<any> {
    return this.httpClient.post<RegisterGetModel>(BASE_URL.concat(environment.register), requestBody);
  }

  resetPassword(payload: { lang: string; login: string }): Observable<any> {
    return this.httpClient.post<any>(BASE_URL.concat(environment.resetPassword), payload);
  }

  getOrganisationInfo() {
    return this.httpClient.get<OrganisationInfoModel>(BASE_URL.concat(environment.info));
  }

  getCurrent() {
    return this.httpClient.get<CurrentModel>(BASE_URL.concat(environment.currentUser));
  }

  getChannels() {
    return this.httpClient.get<ChannelModel[]>(BASE_URL.concat(environment.channels));
  }

  getOrganisations() {
    return this.httpClient.get<OrganisationModel>(BASE_URL.concat(environment.organisations));
  }

  getRoles() {
    return this.httpClient.get<RolesModel>(BASE_URL.concat(environment.roles));
  }

  populateCurrentUser(): Observable<boolean> {
    const observables: [
      Observable<OrganisationInfoModel>,
      Observable<CurrentModel>,
      Observable<ChannelModel[]>,
      Observable<OrganisationModel>,
      Observable<RolesModel>
    ] = [
      this.getOrganisationInfo(),
      this.getCurrent(),
      this.getChannels(),
      this.getOrganisations(),
      this.getRoles()
    ];

    return forkJoin(observables).pipe(
      map(([organisationInfo, current, channels, organisations, rolesArray]) => {
        const currentUser: CurrentUserModel = {
          info: organisationInfo,
          current: current,
          channels: channels,
          organisations: organisations,
          roles: rolesArray
        };

        this.setCurrentUser(currentUser);
        console.log('Current user populated:', currentUser);

        this.setChannelInLocalStorage();

        return true; // Successful population returns true
      }),
      catchError(err => {
        console.error('An error occurred while fetching user data:', err);
        return of(false); // In case of error, return Observable of false
      })
    );
  }

  setCurrentUser(currentUser: CurrentUserModel) {
    this.currentUserSubject.next(currentUser);
  }

  getCurrentUser() {
    return this.currentUserSubject.asObservable();
  }

  setChannel(channel: ChannelModel) {
    this.currentChannel.next(channel);
  }

  getChannel() {
    return this.currentChannel.asObservable();
  }

  isTokenExpired(): boolean {
    let token = localStorage.getItem(AuthService.TOKEN);
    if (!token) {
      return true;
    } else {
      const tokenObject = JSON.parse(token);

      if (!tokenObject) {
        return true;
      }

      const lastAccessDate = new Date(tokenObject.lastAccessDate);
      const expiresIn = tokenObject.expiresIn;

      console.log('your lastAccessDate', lastAccessDate);

      const expirationTime = new Date(lastAccessDate.getTime() + expiresIn);

      console.log('your expirationTime', expirationTime);

      const currentUTCTime = new Date();

      console.log('your time in utc', currentUTCTime);

      let isExpired = currentUTCTime.getTime() > expirationTime.getTime();
      console.log('your token is Expired is', isExpired);

      return isExpired;
    }
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']).then(() => console.trace('Routed to login page'));
  }

  private setChannelInLocalStorage() {
    let channelId = localStorage.getItem(AuthService.CHANNEL_ID);
    let channelUuid = localStorage.getItem(AuthService.CHANNEL_UUID);

    let result: ChannelModel[] = [];
    if (channelId || channelUuid) {
      this.getChannels().subscribe(res => {
        console.log('your res:', res);
        result = res.filter(channel => channel.channelId + '' === channelId || channel.channelUuid === channelUuid);
        console.log('your filtered result is:', result);
        this.setChannelValuesInLocalStorage(result[0]);
      });
    } else {
      this.getChannels().subscribe(res => {
        console.log('your channel result is:', res);
        this.setChannelValuesInLocalStorage(res[0]);
      });
    }
  }

  setChannelValuesInLocalStorage(channel: ChannelModel) {
    localStorage.setItem(AuthService.CHANNEL_ID, channel.channelId + '');
    localStorage.setItem(AuthService.CHANNEL_UUID, channel.channelUuid);
    this.setChannel(channel);
  }
}
